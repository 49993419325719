import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { Dialog, snackbar, UploadButton } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, PRIZE } from 'consts'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { updateNotification } from '../api'
import { UpdateNotificationData, Notification, UpdateNotificationResponse } from '../types'
import { resizeFile } from 'utils';
import { useStyles } from './styles'
import { InputAdornment } from '@material-ui/core'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 50,
      message: 'No puede superar los 50 caracteres',
    },
  ],
  body: [
    {
      validate: (body: string) => body.length <= 192,
      message: 'No puede superar los 192 caracteres',
    },
  ],
  prizeTitle: [
    {
      validate: (title: string) => title.length <= 25,
      message: 'No puede superar los 25 caracteres',
    },
  ],
  prizeShortTitle: [
    {
      validate: (prizeShortTitle: string) => prizeShortTitle.length <= 22,
      message: 'No puede superar los 22 caracteres',
    },
  ],
  additionalText: [
    {
      validate: (value: string) => (value && value.length <= 100) || !value.length,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  description: [
    {
      validate: (description: string) => description.length <= 1024,
      message: 'No puede superar los 1024 caracteres',
    },
  ],
  expirationDays: [
    {
      validate: (value: string) =>
        ((value && Number(value) >= 1) || !value) && Number.isInteger(Number(value)),
      message: 'El vencimiento debe ser un número entero mayor a 0',
    },
  ]
}

interface UpdateNotificationDialogProps {
  notification: Notification
  handleClose: () => void
  onDone: (newNotification: Notification) => void
}

const UpdateNotificationDialog: React.FC<UpdateNotificationDialogProps> = ({ notification, handleClose, onDone }) => {
  const [title, setTitle] = useState(notification.title)
  const [body, setBody] = useState(notification.body)
  const [icon, setIcon] = useState<File | undefined>(undefined)
  const [iconPreview, setIconPreview] = useState(notification.prize.iconUrl)
  const [prizeTitle, setPrizeTitle] = useState(notification.prize.title)
  const [prizeShortTitle, setPrizeShortTitle] = useState(notification.prize.shortTitle)
  const [expirationDays, setExpirationDays] = useState<string>(notification.prize.expirationDays ? notification.prize.expirationDays.toString() : '')
  const [additionalText, setAdditionalText] = useState(notification.prize.additionalText ? notification.prize.additionalText : '')
  const [activePrize, setActivePrize] = useState(notification.activePrize)
  const [active, setActive] = useState(notification.active)
  const [description, setDescription] = useState(notification.description)

  const data = { title, body, prizeTitle, prizeShortTitle, additionalText, description, expirationDays }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const [updateNotificationApi, isLoading] = useApiCall<UpdateNotificationData, UpdateNotificationResponse>(updateNotification)

  const handleSubmit = async () => {
    if (activePrize && !iconPreview) {
      snackbar.show('Para activar el premio es necesario cargar el icono')
      return
    }

    const data = {
      title,
      body,
      icon,
      active,
      activePrize,
      description,
      prize: {
        title: prizeTitle,
        shortTitle: prizeShortTitle,
        additionalText,
        expirationDays,
      }
    }

    try {
      const { notification: updatedNotification } = await updateNotificationApi({ id: notification.id, payload: data })
      onDone(updatedNotification)
    } catch (err) {
      let errorMessage = err.message ?? ERRORS.GENERIC_ERROR_MESSAGE
      snackbar.show(errorMessage)
    }
  }

  const handleIconChange = async (file: File) => {
    setIconPreview(URL.createObjectURL(file))
    const image = await resizeFile(file, 192, 192)
    setIcon(image)
  }

  const handleChangeActivePrize: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActivePrize(!activePrize)
  }

  const handleChangeActive: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActive(!active)
  }

  const classes = useStyles()
  return (
    <Dialog
      title={`Editar notificación`}
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Editar notificación"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <FormControlLabel control={<Checkbox onChange={handleChangeActive} checked={active} />} label="Activar notificación" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descripción Push Automática"
            required
            onChange={e => setDescription(e.target.value)}
            value={description}
            error={errors.body.hasError}
            helperText={errors.description.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Mensaje"
            required
            multiline
            onChange={e => setBody(e.target.value)}
            value={body}
            error={errors.body.hasError}
            helperText={errors.body.message}
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel control={<Checkbox onChange={handleChangeActivePrize} checked={activePrize} />} label="Activar premio" />
        </Grid>
        {activePrize && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Título"
              required
              onChange={e => setPrizeTitle(e.target.value)}
              value={prizeTitle}
              error={errors.prizeTitle.hasError}
              helperText={errors.prizeTitle.message}
            />
          </Grid>
        )}
        {activePrize && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descripción"
              required
              onChange={e => setPrizeShortTitle(e.target.value)}
              value={prizeShortTitle}
              error={errors.prizeShortTitle.hasError}
              helperText={errors.prizeShortTitle.message}
            />
          </Grid>
        )}
        {activePrize && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descripción Adicional"
              required
              onChange={e => setAdditionalText(e.target.value)}
              value={additionalText}
              error={errors.additionalText.hasError}
              helperText={errors.additionalText.message}
            />
          </Grid>
        )}
        {(activePrize && notification.prize.rewardType === PRIZE.REWARD_TYPE.AUTOMATIC) && (
        <>
          <Grid item xs={11} sm={5}>
            <TextField
              value={expirationDays}
              type='number'
              fullWidth
              onChange={e => setExpirationDays(e.target.value)}
              disabled={isLoading}
              label="Vencimiento"
              required
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: <InputAdornment position="start">días</InputAdornment>,
              }}
              error={errors.expirationDays.hasError}
              helperText={errors.expirationDays.message}
            />
          </Grid>
        </>)}
        {activePrize && (
          <>
            <Grid item xs={11}>
              <div className={classes.iconUploadContainer}>
                <UploadButton
                  id="manualReward-icon-upload-button"
                  accept=".jpg, .jpeg, .png"
                  label="Subir ícono"
                  onChange={handleIconChange}
                />
              </div>
            </Grid>

            <Grid item xs={11}>
              <div className={classes.iconUploadPreviewContainer}>
                <img src={iconPreview} alt="" className={classes.iconPreview} />
              </div>
            </Grid>
          </>)}
      </Grid>
    </Dialog>
  )
}

export { UpdateNotificationDialog }
